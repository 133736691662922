@import "minima";

img.thumbnail {
border:4px solid #fefefe;
box-shadow:0 0 0 1px rgba(10,10,10,.2);
display:inline-block;
line-height:0;
max-width:100%;
transition:box-shadow .2s ease-out;
border-radius:0;  
margin-bottom:1rem;
}
img.thumbnail:focus, .thumbnail:hover {
box-shadow:0 0 6px 1px rgba(33,153,232,.5)
}

img.right, div.right, figure.left {
float:right;
}

img.left, div.left, figure.left {
float: left;
}

img.center, div.center, figure.center {
align: center;
}

img.inline-image, div.inline-image, figure.inline-image {
margin: 10px 10px;
background-color: #fff;
padding: 10px;
border-top: 1px solid #999;
border-right: 2px solid #555;
border-bottom: 2px solid #555;
border-left: 1px solid #999;
}

img.inline-image, div.inline-image img {
border-top: 2px solid #555;
border-right: 1px solid #999;
border-bottom: 1px solid #999;
border-left: 2px solid #555;
}

div.inline-image span {
display: block;
word-wrap:break-word;
white-space: normal;
font-size: 76%;
margin-top: 0;
}

figure.inline-image figcaption {
display: block;
margin-top: 1ex;
border-top: 1px solid #999;
word-wrap:break-word;
white-space: normal;
font-size: 76%;
}

span.broken {
text-decoration: underline dotted red;
}

span.broken::after, a.broken::after {
content: " 💔";
text-decoration: none;
}

a.wayback::before {
content: "⏲ ";
text-decoration: none;
}

div.separator, hr.separator {
clear: both;
}

div.comment div.comment__avatar-wrapper { float: left; margin-top: 4px; }
div.comment div.comment__content-wrapper { padding-left: 64px; margin-top: 4px; border-top: 1px solid #999; }
